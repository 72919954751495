import { type INode, NodeDynamicComponentConfig } from '@manager'
import type { ComponentName } from '#build/components.dynamic'

export const getNodeDynamicComponentConfig = (node: INode) => {
  const { directory, path, defaultComponent } =
    NodeDynamicComponentConfig[node.kind]

  const _defaultComponent = defaultComponent(node)

  return {
    path: ['Manager', directory, ...path(node)].join('') as ComponentName,
    defaultComponent: (_defaultComponent === directory
      ? ['Manager', _defaultComponent]
      : ['Manager', directory, _defaultComponent]
    ).join('') as ComponentName,
  }
}
