<script setup lang="ts">
import {
  createEmitsOptions,
  getNodeDynamicComponentConfig,
  useDependentOn,
} from '@manager'
import type { IManagerNodeEmits, IManagerNodeProps } from './types'

const props = defineProps<IManagerNodeProps>()
const emit = defineEmits<IManagerNodeEmits>()
const instance = getCurrentInstance()

const emitsOptions = createEmitsOptions(instance)

// Async Component
const config = getNodeDynamicComponentConfig(props.node)
const { getComponent } = useDynamicComponent(config)
const ManagerNodeComponent = getComponent(config.path)

// The Node don't manage the value
const parentValue = useVModel(props, 'parentValue', emit)

const { evaluate } = useDependentOn(props.node, props.parentPath)
const isVisible = computed(() => evaluate(props.node.dependentOn))
</script>

<template>
  <!-- eslint-disable vue/attributes-order -->
  <KeepAlive v-if="node.isEnabled">
    <component
      v-if="isVisible"
      :is="ManagerNodeComponent"
      v-bind="props"
      v-on="emitsOptions"
      v-model:parent-value="parentValue"
    />
  </KeepAlive>
  <!-- eslint-enable vue/attributes-order -->
</template>
